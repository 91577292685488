import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import listPlugin from '@fullcalendar/list'
import rrulePlugin from '@fullcalendar/rrule'
import scrollGridPlugin from '@fullcalendar/scrollgrid'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import EventProcessor from './event_processor'

(->
  class EventCalendar
    initialize: (event_url, modal_view = true, initial_view = 'timeGridDay', default_date, hide_headers = false, show_min_max_slots = false, popover_view = false, base_url='', extraParams = {}) ->
      fetch('/fullcalendar_license')
        .then((response) -> response.json())
        .then((data) ->
          $.ajax
            type: 'GET'
            url: event_url
            success: ({results, resources, min_slot_time, max_slot_time}) ->
              calendarEl = document.getElementById('calendar')
              calendar = new Calendar(calendarEl,
                schedulerLicenseKey: data.access_key
                nowIndicator: true
                editable: if base_url then true else false
                droppable:  if base_url then true else false
                eventResizableFromStart:  if base_url then true else false
                headerToolbar: getHeaderToolbarConfig(extraParams.date_view)
                plugins: [
                  resourceTimeGridPlugin
                  dayGridPlugin
                  timeGridPlugin
                  interactionPlugin
                  bootstrapPlugin
                  rrulePlugin
                  listPlugin
                  scrollGridPlugin
                ]
                themeSystem: 'bootstrap'
                validRange: { start: extraParams.date_range_begin, end: extraParams.date_range_end }
                initialView: initial_view
                resourceOrder: 'op_order'
                resources: if resources then resources
                events: results
                height: 'auto'
                timeZone: 'UTC'
                dayMinWidth: 100
                initialDate: default_date
                slotMinTime: if show_min_max_slots then min_slot_time else "00:00"
                slotMaxTime: if show_min_max_slots then max_slot_time else "24:00"
                allDaySlot: false
                eventDidMount: (info) ->
                  eventEl = info.el
                  $(eventEl).attr('data-modal', true) if modal_view

                  createDeleteIcon(info, base_url) if base_url

                  if popover_view
                    $(eventEl).attr('data-toggle', 'popover')
                    $(eventEl).attr('data-trigger', 'hover')
                    $(eventEl).attr('data-html', true)
                    $(eventEl).attr('data-content', info.event.extendedProps.html)
                    $(eventEl).attr('disabled', true)

                eventClick: (info) ->
                  if !info.event.url
                    info.jsEvent.preventDefault()
                    return

                  $.ajax
                    type: 'GET'
                    url: info.event.url

                eventDrop: (info) ->
                  processEvent(info, base_url) if base_url

                eventResize: (info) ->
                  processEvent(info, base_url) if base_url

                dateClick: (info) ->
                  processDate(info, calendar, base_url) if base_url

                resourceLabelDidMount: (arg) ->
                  resourceEl = arg.el
                  resourcePhantom = arg.resource.extendedProps.phantom
                  if resourcePhantom
                    resourceEl.style.backgroundColor = 'grey'

              )
              calendar.render()
              custom_styling() if hide_headers
        )

    custom_styling = ->
      $(".fc-header-toolbar").addClass 'd-none'

    createDeleteIcon = (info, base_url) ->
      deleteIcon = $('<span class="delete-icon">&times;</span>')
      $(info.el).append(deleteIcon)

      deleteIcon.css
        position: 'absolute'
        top: '0px'
        right: '1px'
        backgroundColor: '#a64747'
        borderRadius: '50%'
        width: '17px'
        height: '17px'
        display: 'flex'
        justifyContent: 'center'
        alignItems: 'center'
        color: 'white'
        fontSize: '14px'
        cursor: 'pointer'
        zIndex: 10

      deleteIcon.html('&times;')

      deleteIcon.on 'click', (e) ->
        e.stopPropagation()
        if confirm('Are you sure you want to delete this event?')
          eventProcessor = new EventProcessor(base_url)
          eventProcessor.process('deleteEvent', info)
          info.event.remove()

    processEvent = (info, base_url) ->
      eventProcessor = new EventProcessor(base_url)
      eventProcessor.process('processEvent', info)

    processDate = (info, calendar, base_url) ->
      eventProcessor = new EventProcessor(base_url, calendar)
      eventProcessor.process('processDate', info)

    getHeaderToolbarConfig = (date_view) ->
      defaultConfig = 
        center: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'

      if date_view
        defaultConfig.right = 'prev,next'
        switch date_view
          when 'monthly'
            defaultConfig.center = 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
          when 'weekly'
            defaultConfig.center = 'timeGridWeek,timeGridDay,listWeek'
          when 'daily'
            defaultConfig.center = ''
            defaultConfig.right = 'prev,next'

      defaultConfig

  window.EventCalendar = new EventCalendar()

).call(this)
