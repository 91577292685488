import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["newButton", "editButton", "table", "endDate", "checkbox", "endDateWrapper", "endDateRow"]

  connect() {
    if (this.hasTableTarget) {
      this.initializeState()
      this.observeTableChanges()
    }
    this.updateVisibility()
    this.listenForTurboFrameLoad()
  }

  listenForTurboFrameLoad() {    
    document.addEventListener("turbo:frame-load", (event) => {
      const frame = event.target
      if (frame.id === "table_frame_id") {
        this.initializeState()
        this.observeTableChanges()
      }
    })
  }

  updateVisibility() {
    const isChecked = this.checkboxTarget?.checked
    const hasEndDate = !!this.endDateTarget?.value

    this.toggleClass(this.endDateWrapperTarget, "d-none", isChecked)
    this.toggleClass(this.endDateRowTarget, "d-none", hasEndDate)
  }

  toggleEndDateField() {
    const hasEndDate = !!this.endDateTarget?.value
    this.toggleClass(this.endDateRowTarget, "d-none", hasEndDate)
  }

  toggleEndDate() {
    const isChecked = this.checkboxTarget?.checked
    this.toggleClass(this.endDateWrapperTarget, "d-none", isChecked)
  }

  toggleClass(element, className, condition) {
    if (element) {
      element.classList.toggle(className, condition)
    }
  }

  initializeState() {
    if (this.hasNewButtonTarget) {
      this.checkIfTableIsEmpty()
    }

    this.editButtonTargets.forEach(button => {
      button.classList.remove("d-none")
    })
  }

  toggleEditButton(event) {
    event.currentTarget.classList.add("d-none")

    this.editButtonTargets.forEach(button => {
      if (button !== event.currentTarget) {
        button.classList.remove("d-none")
      }
    })
  
    if (this.hasNewButtonTarget) {
      this.newButtonTarget.classList.remove("d-none")
    }
  }  

  toggleNewButton() {
    this.editButtonTargets.forEach(button => {
      button.classList.remove("d-none")
    })

    if (this.hasNewButtonTarget) {
      this.newButtonTarget.classList.add("d-none")
    }
  }

  observeTableChanges() {
    if (!this.hasTableTarget) {
      return
    }

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach(mutation => {
        if (mutation.type === "childList") {
          this.checkIfTableIsEmpty()

          if (mutation.addedNodes.length > 0) {
            const lastEditButton = this.editButtonTargets[this.editButtonTargets.length - 1]

            if (lastEditButton) {
              lastEditButton.classList.add("d-none")

              if (this.hasNewButtonTarget) {
                this.newButtonTarget.classList.remove("d-none")
              }
            }
          }
        }
      })
    })

    observer.observe(this.tableTarget, {
      childList: true,
      subtree: true,
    })
  }

  checkIfTableIsEmpty() {
    const rows = this.tableTarget.querySelectorAll("tr")

    if (rows.length === 0) {
      if (this.hasNewButtonTarget) {
        this.newButtonTarget.classList.add("d-none")
      }
    } else {
      if (this.hasNewButtonTarget) {
        this.newButtonTarget.classList.remove("d-none")
      }
    }
  }
}
