import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "hideForm"];
  connect() {
    if(this.hasHideFormTarget && (this.hideFormTarget.value == 'true')){
      this.submitTreatmentPlan();
    }
  }

  submitTreatmentPlan() {
    this.element.addEventListener("total-dollars-changed", () => {
      if (this.hasFormTarget) {
        this.formTarget.click();
      }
    });
  }
}
