import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dataProvider', 'pmsType'];
  connect() {
    this.handleDataProviderSelection();
  }

  handleDataProviderSelection(){
    $(this.pmsTypeTarget).on('change', ()=>{
      var pmsTypeValue = this.pmsTypeTarget.value;
      if (pmsTypeValue == 'dentrix'){
        this.dataProviderTarget.value = 'bahammas';
      }
      else if(pmsTypeValue == 'dentrix_ascend'){
        this.dataProviderTarget.value = 'dentrix_ascend_provider';
      }
      else if(pmsTypeValue == 'open_dental_cloud' || pmsTypeValue == 'opendental'){
        this.dataProviderTarget.value = 'open_dental';
      }
      else if(pmsTypeValue == 'eaglesoft'){
        this.dataProviderTarget.value = 'velox';
      }
      else{
        this.dataProviderTarget.value = 'sikka';
      }
    })
  }
}
