import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    eventUrl: String,
    modalView: Boolean,
    initialView: String,
    defaultDate: String,
    hideHeaders: Boolean,
    showMinMaxSlots: Boolean,
    popoverView: Boolean,
    baseUrl: String,
    extraParams: String
  }

  connect() {
    const extraParams = JSON.parse(this.extraParamsValue)

    this.calendarInitializer(
      this.eventUrlValue, 
      this.modalViewValue, 
      this.initialViewValue, 
      this.defaultDateValue, 
      this.hideHeadersValue, 
      this.showMinMaxSlotsValue, 
      this.popoverViewValue,
      this.baseUrlValue,
      extraParams
    )
  }

  calendarInitializer(eventUrl, modalView = true, initialView = 'timeGridDay', defaultDate, hideHeaders = false, showMinMaxSlots = false, popoverView = false, baseUrl, extraParams) {
    EventCalendar.initialize(eventUrl, modalView, initialView, defaultDate, hideHeaders, showMinMaxSlots, popoverView, baseUrl, extraParams);
  }
}
