import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  toggleDisabledView(event) {
    const day = event.target.dataset.day
    const button = document.getElementById(`edit-capacity-button-${day}`)

    if (event.target.checked) {
      button.classList.remove("disabled-view")
    } else {
      button.classList.add("disabled-view")
    }
  }
}
