import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    PaymentProcessor.initialize(
      this.data.get("redirectPath"),
      this.data.get("clientSecret"),
      this.data.get("hasSavedPaymentMethods")
    );
  }
}
