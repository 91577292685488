import CapacityTimePeriodProcessor from './capacity_time_period_processor'

class EventProcessor {
  constructor(base_url, calendar) {
    this.base_url = base_url
    this.calendar = calendar
    this.classMap = {
      "/capacity_time_period": CapacityTimePeriodProcessor,
      // Add more mappings as needed
    }
    this.processorInstances = {}
  }

  /**
   * Dynamically determine the processor class based on the base_url.
   */
  getProcessorClass() {
    const match = Object.keys(this.classMap).find((key) => this.base_url.includes(key))
    if (match) return this.classMap[match]
    alert(`No processor class found for base url: ${this.base_url}`)
    return null
  }

  /**
   * Load and initialize the determined processor class if not already loaded.
   */
  loadProcessor() {
    const ProcessorClass = this.getProcessorClass()
    if (!ProcessorClass) return null

    if (!this.processorInstances[ProcessorClass.name]) {
      this.processorInstances[ProcessorClass.name] = new ProcessorClass(this.base_url, this.calendar)
    }
    return this.processorInstances[ProcessorClass.name]
  }

  /**
   * Automatically delegate the action (method) to the appropriate processor.
   * @param {String} methodName - The method to call in the processor class.
   * @param {Object} params - The parameters to pass to the method.
   */
  process(methodName, params) {
    const processor = this.loadProcessor()
    if (processor && typeof processor[methodName] === "function") {
      return processor[methodName](params)
    } else {
      alert(
        `Method '${methodName}' not found in the processor class associated with base url: ${this.base_url}`
      )
    }
  }
}

export default EventProcessor
