export default class CapacityTimePeriodProcessor {
  constructor(base_url, calendar) {
    this.base_url = base_url
    this.calendar = calendar
  }

  createPayload(info) {
    const { params, operatory_id } = info
    return {
      id: params.event?.id,
      start_time: params.event.start.toISOString(),
      end_time: params.event.end.toISOString(),
      operatory_id: operatory_id
    }
  }

  processEvent(params) {
    const resource_id = params.event.getResources()[0]?._resource?.id
    if (!resource_id) return

    const payload = this.createPayload({ params, operatory_id: resource_id })
    this.addEntity(payload)
  }

  processDate(params) {
    const resource_id = params.resource?._resource?.id
    if (!resource_id) return

    const startTime = params.date
    const endTime = new Date(startTime)
    endTime.setHours(startTime.getHours() + 1)

    const payload = {
      day_of_week: startTime.toLocaleDateString("en-US", { weekday: "long" }),
      start_time: startTime.toISOString(),
      end_time: endTime.toISOString(),
      operatory_id: resource_id
    }

    this.addEntity(payload)
  }

  deleteEvent(params) {
    this.deleteEntity(params)
  }

  deleteEntity(params) {
    const time_slot_id = params.event.id
    const url = `${this.base_url}/${time_slot_id}`
    this.sendRequest(url, 'DELETE')
      .then((data) => {
        const row = document.querySelector(`#time-slot-row-${data.time_slot}`)
        if (row) row.remove()
      })
      .catch((error) => {
        alert("An error occurred while deleting the event: " + error.message)
      })
  }

  addEntity(payload) {
    const method = payload.id ? "PUT" : "POST"
    const url = payload.id ? `${this.base_url}/${payload.id}` : this.base_url
    this.sendRequest(url, method, { time_slot: payload })
      .then((data) => {
        if (payload.id) {
          const row = document.querySelector(`#time-slot-row-${payload.id || data.time_slot.id}`)
          row.innerHTML = data.html
        } else {
          const tbody = document.querySelector(`#table-${data.capacity_time_period_id}-${data.day}`)
          tbody.innerHTML += data.content
          this.addCalendarEvent(data.time_slot_id, data.operatory_id, payload)
        }
      })
      .catch((error) => {
        alert("An error occurred: " + error.message)
      })
  }

  addCalendarEvent(time_slot_id, operatory_id, payload) {
    const { start_time, end_time } = payload

    const event = {
      id: time_slot_id,
      resourceId: operatory_id,
      allDay: false,
      start: start_time,
      end: end_time
    }

    this.calendar.addEvent(event)
  }

  sendRequest(url, method, payload = null) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")
    return fetch(url, {
      method: method,
      body: payload ? JSON.stringify(payload) : null,
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.message || 'Something went wrong')
          })
        }
        return res.json()
      })
  }
}
