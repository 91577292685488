import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["treatment", "txpPatient", 'appointmentPatient', "appointmentDate", "txpDate", "txpListOutput", "treatmentIds", "totalDollarsField", "clickOnLoad"]

  static values = {
    treatmentList: Array
  }
  changeCounter = 0;

  connect(){
    $(this.totalDollarsFieldTarget).on("change", () => {
      setTimeout(() => {
        this.changeCounter += 1;
        if(this.changeCounter == 2){
          this.element.dispatchEvent(new CustomEvent("total-dollars-changed", { bubbles: true }));
          this.changeCounter = 0;
        }
      },0);
    });
  }

  txpPatientTargetConnected(element) {
    if (!this.hasTxpPatientTarget) return;

    element = this.txpPatientTarget
    let patient_id = element.value
    // let date = this.txpDateTarget.value

    this.updateTreatmentList(patient_id);

    $(element).on('change', () => {
      // let date = this.txpDateTarget.value
      let patient_id = this.txpPatientTarget.value

      this.updateTreatmentList(patient_id)
    });

    this.releaseTx = this.releaseTx.bind(this);
    $(this.element).on("cocoon:before-remove", this.releaseTx);
  }

  appointmentPatientTargetConnected(element) {
    if (!this.hasAppointmentPatientTarget) return;

    element = this.appointmentPatientTarget
    let patient_id = element.value
    // let date = this.appointmentDateTarget.value

    this.updateTreatmentList(patient_id);

    $(element).on('change', () => {
      // let date = this.appointmentDateTarget.value
      let patient_id = this.appointmentPatientTarget.value

      this.updateTreatmentList(patient_id)
    });
  }

  updateTreatmentList = (patient_id, date = null) => {
    if (patient_id !== '') {
      return $.ajax({
        type: 'GET',
        url: '/treatments/patient_treatments',
        dataType: 'html',
        data: {
          patient_id: patient_id,
          date: date
        },
        success: (result) => {
          this.treatmentList = result;

          if (this.treatmentList.length > 0) {
            const checkedIds = $(this.element).find('[data-title-generator-target="treatmentIds"]').map((_, el) => $(el).val()).get();
            this.treatmentList = alterTxListById(checkedIds, this.treatmentList)
            this.refreshTxListOutput()
          }
        }
      });
    }
  };

  txpListOutputTargetConnected(element) {
    const dropdown = getNearestTxpListOutput(element)
    const list = setListIcon(this.treatmentList)
    const lineItemTreatmentIds = getTxLineItemTreatmentIds(element)
    this.treatmentList = alterTxListById(lineItemTreatmentIds, list)
    dropdown.innerHTML = this.treatmentList
  }

  clickOnLoadTargetConnected(element) {
    this.treatmentListObserver = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          const treatmentList = $(this.element).find('[data-title-generator-target="txpListOutput"] tr:first-child');
          if (treatmentList.length > 0) {
            console.log('Treatment list loaded');
            treatmentList.trigger('click').trigger('click'); // Double click to trigger event and still maintain element state
            this.treatmentListObserver.disconnect();
          }
        }
      }
    });

    const targetNode = this.txpListOutputTarget;
    if (targetNode) {
      this.treatmentListObserver.observe(targetNode, { childList: true });
    }
  }

  refetchTreatments() {
    var patient_id = null
    if (this.hasAppointmentPatientTarget) {
      patient_id = this.appointmentPatientTarget.value
    } else if (this.hasTxpPatientTarget) {
      patient_id = this.txpPatientTarget.value
    }
    this.updateTreatmentList(patient_id)
  }

  select(event) {
    let currentElement = event.currentTarget
    const icon = currentElement.querySelector('i');
    const checkbox = currentElement.querySelector('input[type="checkbox"]');
    currentElement = checkbox
    const dropdown = getNearestTxpListOutput(currentElement)
    const lineItemTreatmentIds = getTxLineItemTreatmentIds(currentElement)
    const seletedTreatmentId = checkbox.value

    if (checkbox) $(checkbox).attr('checked', !currentElement.checked)
    $(`#${seletedTreatmentId}-checkbox`).attr('checked', currentElement.checked)

    let treatmentIdsArray = lineItemTreatmentIds

    if (checkbox.checked) {
      treatmentIdsArray.push(seletedTreatmentId);
      addTx(currentElement, seletedTreatmentId)
      icon.classList = 'fa fa-check text-teal'
    } else {
      treatmentIdsArray = treatmentIdsArray.filter(value => value !== seletedTreatmentId);
      removeTx(currentElement, seletedTreatmentId)
      icon.classList = 'fa-regular fa-square'
    }

    this.treatmentList = dropdown.innerHTML
    getTxLineItemTitle(currentElement).val(getTitleGenerator(treatmentIdsArray, this.treatmentList))
    getTxLineItemTotalDollars(currentElement).val(getTotalDollars(treatmentIdsArray, this.treatmentList))
    this.refreshTxListOutput(dropdown)

    $(getTxLineItemTotalDollars(currentElement)).trigger("change")
    $(getTxLineItemTotalDollars(currentElement)).trigger("keyup")
  }

  releaseTx(event, removingElement) {
    const removingTxIds = $(removingElement).find('[data-title-generator-target="treatmentIds"]').map((_, el) => $(el).val()).get();
    this.treatmentList = uncheckTxListById(removingTxIds, this.treatmentList)
    this.refreshTxListOutput()
  }

  refreshTxListOutput(exceptTarget = null) {
    this.txpListOutputTargets
      .filter(_el => !exceptTarget || _el !== exceptTarget)
      .forEach(_el => {
        this.txpListOutputTargetConnected(_el);
      });
  }

  disconnect() {
    if (this.treatmentListObserver) {
      this.treatmentListObserver.disconnect();
    }
  }
}

function getTxLineItemName(element) {
  let parentName = getTxLineItemTitle(element).attr('name')
  return parentName.split('[title]')[0]
}

function removeTx(element, id) {
  $(element).closest('.fields').find('input[type="hidden"][value="' + id + '"]').remove()
}

function addTx(element, id) {
  $(element).closest('.fields').prepend(
    $('<input>').attr({
      type: 'hidden',
      multiple: 'multiple',
      'data-title-generator-target': "treatmentIds",
      name: `${getTxLineItemName(element)}[treatment_ids[]`,
      value: id
    })
  );
}

function getTxLineItemTreatmentIds(element) {
  return getTxLineItemTreatmentIdsElements(element).map((_, el) => $(el).val()).get();
}

function getTxLineItemTreatmentIdsElements(element) {
  return $(element).closest('.fields').find('[data-title-generator-target="treatmentIds"]')
}

function getTxLineItemTitle(element) {
  return $(element).closest('.fields').find('[data_target="title-generator.titleField"]')
}

function getTxLineItemTotalDollars(element) {
  return $(element).closest('.fields').find('[data-title-generator-target="totalDollarsField"]')
}

function getNearestTxpListOutput(element) {
  return element.closest('.fields').querySelector('[data-title-generator-target="txpListOutput"]');
}

function filterTxListByItem(ids, list) {
  var treatmentArray = []
  const wrapper = document.createElement('div');
  wrapper.innerHTML = list

  wrapper.querySelectorAll('.treatments-filterable').forEach((item) => {
    var checkbox = $(item).find('input[type="checkbox"]');

    if (checkbox.length > 0) {
      const isChecked = checkbox.prop('checked');
      const hasMatchingClass = ids.includes(checkbox.val());

      if (isChecked && hasMatchingClass) {

        treatmentArray.push({
          title: $(item).find('.title').text(),
          code: $(item).find('.code').text(),
          location: $(item).find('.location').text(),
          cost: converter.convertCurrencyToFloat($(item).find('.cost').text()) || 0
        })
      }
    }
  });
  return treatmentArray
}

function getTotalDollars(ids, list) {
  var treatmentArray = filterTxListByItem(ids, list)

  return treatmentArray.reduce((sum, tx) => sum + tx.cost, 0);
}

function getTitleGenerator(ids, list) {
  var treatmentArray = filterTxListByItem(ids, list)

  return consolidateTreatmentsToTitle(treatmentArray)
}

function consolidateTreatmentsToTitle(treatmentArray) {
  const groupedByLocation = {};

  // Group treatments by location
  treatmentArray.forEach(treatment => {
    if (!groupedByLocation[treatment.location]) {
      groupedByLocation[treatment.location] = [];
    }
    groupedByLocation[treatment.location].push(treatment.title);
  });

  // Construct descriptions
  const descriptions = [];
  for (const location in groupedByLocation) {
    const titles = groupedByLocation[location];
    const obj = {};
    const titlesStr = titles.length > 1 ? `${titles.join(', ')}` : `${titles[0]}`;
    obj[titlesStr] = location;
    descriptions.push(obj)
  }

  return groupByKeyAndJoinValues(descriptions)
}

function groupByKeyAndJoinValues(array) {
  const grouped = {};

  array.forEach(obj => {
    for (const key in obj) {
      if (grouped[key] === undefined) {
        grouped[key] = [];
      }
      grouped[key].push(obj[key]);
    }
  });

  const result = [];
  for (const key in grouped) {
    grouped[key] = grouped[key].filter((v) => (v !== '0' && v !== ''));

    if (grouped[key].length == 0) {
      result.push(`${key}`);
    } else {
      result.push(`${key} (${grouped[key].join(', ')})`);
    }
  }

  return result.join(', ');
}

function setListIcon(list) {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = list

  wrapper.querySelectorAll('.treatments-filterable').forEach((item) => {
    var checkbox = $(item).find('input[type="checkbox"]');
    const icon = item.querySelector('i');

    if (checkbox.length > 0) {
      const isChecked = checkbox.prop('checked');
      if (isChecked) { icon.classList = 'fa fa-check text-teal' } else { icon.classList = 'fa-regular fa-square' }
    }
  });
  return wrapper.innerHTML;
}

function alterTxListById(ids, list) {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = list

  wrapper.querySelectorAll('.treatments-filterable').forEach((item) => {
    var checkbox = $(item).find('input[type="checkbox"]');

    if (checkbox.length > 0) {
      const isChecked = checkbox.prop('checked');
      const hasMatchingIds = ids.includes(checkbox.val());

      if (hasMatchingIds) { checkbox.attr('checked', true); }

      // Determine whether to show or hide the dropdown item based on the conditions
      const shouldShow = !isChecked || hasMatchingIds;

      // Toggle the visibility of the dropdown item accordingly
      if (shouldShow) { $(item).removeClass('d-none') } else { $(item).addClass('d-none') }
    }
  });
  return wrapper.innerHTML;
}

function uncheckTxListById(ids, list) {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = list

  wrapper.querySelectorAll('.treatments-filterable').forEach((item) => {
    var checkbox = $(item).find('input[type="checkbox"]');

    if (checkbox.length > 0) {
      const hasMatchingIds = ids.includes(checkbox.val());

      if (hasMatchingIds) {
        checkbox.attr('checked', false);
        $(item).removeClass('d-none')
      }
    }
  });
  return wrapper.innerHTML;
}
