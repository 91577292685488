practices = require('./practice_controller')

(->
  class CouponCodeProcessor
     initialize: (controller_name) ->
      @controller_name = controller_name
      coupon_code_btn = document.getElementById("coupon_code_btn")
      
      if coupon_code_btn
        coupon_code_btn.addEventListener 'click', (event) ->
          event.preventDefault()
          coupon_code = $("#coupon_code").val()
          practices.reloadForm({
            coupon_code: coupon_code,
            tier: $("#practice-pricing :selected").val()
          })
   window.CouponCodeProcessor = new CouponCodeProcessor()
).call this
